$(function(){
  var url_parts = window.location.pathname.split('/'),
      page = url_parts[1],
      $menu_item = $(".nav-link[href^='/"+page+"']");

  if(page == ""){
    $(".nav-link[href='/']").addClass("active");
  } else {
    $menu_item.addClass("active");
  }
});
