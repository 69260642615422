$(function(){
  // update account - request account password
  $("#edit_account").on("submit", function(e){
    if($("#confirm_password").val() == ''){
      e.preventDefault();

      swal({
        title: "Update Account",
        text: "Enter your password to confirm changes",
        input: "password",
        confirmButtonText: "Save Changes",
        confirmButtonColor: "#5fbeaa",
        inputPlaceholder: "Password",
        preConfirm: function(name){
          return new Promise(function(resolve, reject) {
            if (name === '') {
              reject('Please enter your password.');
            } else {
              resolve();
            }
          });
        }
      }).then(function(password){
        $("#confirm_password").val(password);
        $("#edit_account").submit();
      }, function(dismiss){
        // dismiss can be 'cancel', 'overlay', 'close', 'timer'
        $("#edit_account").find("input[type=submit]").removeAttr("disabled");
      });
    }
  });
});