$(function(){
  if($("#slideshow").length > 0){
    // $("#slideshow").removeClass("d-none");

    $("#slideshow").slippry({
      elements: 'div',
      // transition: 'fade',
      // useCSS: true,
      // speed: 1000,
      // pause: 3000,
      // auto: true,
      // preload: 'visible'
      // autoHover: false
    });
  }

  $("#show-tel").on("click", function(e){
    if(!$("#show-tel").hasClass("active")){
      e.preventDefault();

      var $this = $(this);
      var tel = $this.data("telephone") != "" ? "tel:" + $this.data("telephone") : "Number not available";
      $this.attr("href", tel ).addClass("active");
      $this.find("span").text( $this.data("telephone") );
    }
  });

  $("#toggleDetails").on("click", function(){
    var details = $("#prop-details");
    details.toggleClass("open");
    if(details.hasClass("open")){
      $(this).text("Show less");
    } else {
      $(this).text("Show more");
    }
  });

  $("#send-message-to-agent").on("click", function(){
    var agent_id = $("#agent_id").val(),
        property_id = $("#property_id").val(),
        name = $("#message-name").val(),
        email = $("#message-email").val(),
        phone = $("#message-phone").val(),
        date = $("#viewing-date").val(),
        message = $("#message-text").val();

    $.ajax({
      url: "/agent/message",
      method: "POST",
      dataType: "json",
      data: {
        agent_id: agent_id,
        property_id: property_id,
        name: name,
        email: email,
        phone: phone,
        date: date,
        message: message
      },
      success: function(data){
        if(data.status == "success"){
          $('#contact-modal form').remove();
          $('#send-message-to-agent').remove();
          $('#contact-modal .modal-body').html('<div class="alert alert-success">Your message has been sent. Thank you!</div>');
        } else {
          $('#contact-modal .modal-body').append('<div class="alert alert-danger">Please complete all fields and submit again.</div>');
        }
      }
    });
  });

  $('#contact-modal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var modal = $(this);
  });

  // toggle lease term, only appears for rental properties
  $(document).on("change", "form select#property_for_sale", function(e){
    var $select = $(this);

    if( this.value == "false" ){
      $("#property_lease_field").removeClass("d-none");
    } else if( this.value == "true" ){
      $("#property_lease_field").addClass("d-none");
    }
  });

  // populate address when selecting project, popup for new project suggestions
  $(document).on("change", "form select#property_project_id", function(e){
    var $select = $(this);

    if( this.value == "new_project_suggestion" ){
      swal({
        title: "Suggest new condo/apartment",
        text: "Is your building missing? Suggest the condo/apartment/development and we'll add it to the website.",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Save",
        confirmButtonColor: "#5fbeaa",
        closeOnConfirm: false,
        allowOutsideClick: true,
        inputPlaceholder: "Project Name",
        preConfirm: function(name){
          return new Promise(function(resolve, reject) {
            if (name === '') {
              reject('Enter a project name.');
            } else {
              resolve();
            }
          });
        }
      }).then(function(name){
        // make ajax request to save suggestion
        $.ajax({
          url: '/projects/suggestion',
          dataType: "JSON",
          method: "POST",
          data: {
            'name': name
          }
        })
        .done(function(data){
          swal({ title: "Thank you", text: "We will review suggestions and add new developments to the website." });
          $select.val("");
          $("#property_address").val("").removeAttr('disabled');
        });
      }, function(dismiss){
        // user cancelled or clicked outside swal
        $select.val("");
        return false;
      });
    } else if( this.value != "" ){
      $.get("/project/address?id="+this.value, function(address){
        $("#property_address").val(address).attr('disabled', 'disabled');
      });
    } else if( this.value == "" ){
      $("#property_address").val("").removeAttr('disabled');
    }  
  });
});
