// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();

require("./includes/vendor/slippry.min");
require("./includes/vendor/sweetalert2.min");

require("@fortawesome/fontawesome-free/js/all.min");

import toastr from 'toastr';
toastr.options = {
  progressBar: true
}
global.toastr = toastr;

var Trix = require("trix");
require("@rails/actiontext");
Trix.config.blockAttributes.heading1 = { tagName: 'h2' };

require("./includes/menu");
require("./includes/trix_editor");
require("./includes/properties");
require("./includes/popups");
require("./includes/direct-upload");
require("./includes/drag-drop-upload");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
