window.onload = function(){
  dropArea = document.getElementById("dropzone");

  if(dropArea){
    function preventDefaults (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    function highlight(e) {
      dropArea.classList.add('highlight')
    }

    function unhighlight(e) {
      dropArea.classList.remove('active')
    }

    // Prevent default drag behaviors
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, preventDefaults, false);
      document.body.addEventListener(eventName, preventDefaults, false);
    });

    // Highlight drop area when item is dragged over it
    ['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, highlight, false);
    });

    ['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });

    // Handle dropped files
    dropArea.addEventListener('drop', handleDrop, false);
  
    // show file name on label when selecting file manually
    document.getElementById("fileupload").addEventListener('change', function(){
      if(this.files.length > 0){
        if(this.files.length == 1){
          var label = this.files[0]['name'];
        } else if (this.files.length > 1){
          var label = this.files.length + " photos selected";
        }

        document.getElementById("dropzone").innerHTML = label + "<br /><small>Click Save to upload</small>";
      }
      // previewFile(this.files[0]);
    }, false);

    function handleDrop(evt) {
      var field = document.getElementById("fileupload");
      var file = evt.dataTransfer.files[0];
      var total_files = evt.dataTransfer.files.length;
      field.files = evt.dataTransfer.files;

      if(total_files > 1){
        var label = total_files + " photos selected";
      } else {
        var label = file.name;
      }

      document.getElementById("dropzone").innerHTML = label + "<br /><small>Click Save to upload</small>";
      // previewFile(file);
    }

    // function previewFile(file) {
    //   let reader = new FileReader()
    //   reader.readAsDataURL(file)
    //   reader.onloadend = function() {
    //     let img = document.createElement('img');
    //     img.src = reader.result;
    //     img.setAttribute("class", "img-fluid ml-4");
    //     document.getElementById('upload-preview').appendChild(img)
    //   }
    // }
  }
}
